import React from 'react';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <>
    <div className="w-full flex flex-col justify-center content-center items-center h-screen">
      <p className="font-extrabold mb-4">NOT FOUND</p>
      <p className="mb-12">You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/">
      <button className="bg-blue-600 rounded border border-blue-400 p-2 hover:bg-blue-800 hover:border-blue-600">
        <p className="text-blue-100">
          Go Home
      </p>
      </button>
      </Link>
    </div>
  </>
)

export default NotFoundPage;
